import React from "react";

function Map() {
  return (
    <div className="w-[350px] h-[350px] md:w-[600px]">
      <iframe
        src="https://snazzymaps.com/embed/517175"
        title="Kumo Karate Do & Kobudo Dojo Mapa"
        width="100%"
        height="100%"
        style={{border: "none", borderRadius: "40px"}}
      ></iframe>
    </div>
  );
}

export default Map;