import React from "react";
import Divider from "./Divider";

function ProgramsCard({ image, title, description, order }) {
  return (
    <div
      className="bg-black bg-contain bg-no-repeat w-[300px] h-[550px] rounded-3xl flex flex-col justify-end shadow-xl shadow-gray-700 mb-6"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Divider />
      <div className=" flex h-[45%] flex-col justify-start items-center rounded-b-3xl">
        <div>
        <h1 className="text-3xl text-white mb-6 bg-RojoKumo w-full text-end pr-4 pb-4 border-b-4 border-GrisKumo">{title}</h1>
        <p className=" text-white font-normal text-center px-4">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default ProgramsCard;

