import React from "react";
import { motion } from "framer-motion";
import { BrowserView, MobileView } from "react-device-detect";
import Logo from "./Logo";

function Hero() {
  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <BrowserView
        className="w-full h-screen bg-black bg-no-repeat bg-cover bg-right-top"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dt9ivv2ug/image/upload/v1692225290/HeroHorizontal.jpg')",
        }}
        alt="Karate Niños en Clase"
      >
        <div className="flex flex-row text-white">
          <div className="w-[50%] h-screen flex flex-col justify-center items-center">
            <Logo className="w-24 h-24 mb-4" fill="#ff0000" />
            <h1 className="text-5xl font-bold">Kumo</h1>
            <h1 className="text-5xl font-bold">Karate Do</h1>
            <h1 className="text-5xl font-bold">& Kobudo Dojo</h1>
            <button
              className="w-72 h-14 bg-RojoKumo rounded-2xl my-20 shadow-lg shadow-black"
              onClick={() => window.open("http://wa.me/525569661949", "_blank")}            >
              Quiero Informes
            </button>
          </div>
          <div className="w-[50%] h-screen"></div>
        </div>
      </BrowserView>

      {/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><MOBILE<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
      <MobileView
        className="w-full h-screen bg-black bg-no-repeat bg-cover bg-bottom"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dt9ivv2ug/image/upload/v1692225320/HeroVertical.jpg')",
        }}
        alt="Karate Niños en Clase"
      >
        <div className="flex flex-col text-white">
          <div className="w-full h-screen flex flex-col justify-start mt-10 items-center">
            <Logo className="w-24 h-24 mb-4" fill="#ff0000" />
            <h1 className="text-4xl font-bold">Kumo</h1>
            <h1 className="text-4xl font-bold">Karate Do</h1>
            <h1 className="text-4xl font-bold">& Kobudo Dojo</h1>
            <motion.button
              whileHover={{ y: -5 }}
              initial={{ y: 0 }}
              className="w-56 h-10 bg-RojoKumo rounded-2xl my-10 shadow-lg shadow-black"
              onClick={() => window.open("http://wa.me/525569661949", "_blank")}>
              Quiero Informes
            </motion.button>
          </div>
        </div>
      </MobileView>
    </>
  );
}

export default Hero;
