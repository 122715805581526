import React from "react";
import ProgramsCard from "./ProgramsCard";

function Programs() {
  return (
    <>
      <div className="flex flex-col justify-center items-center min-h-screen">
        <h1 className="text-3xl md:text-4xl lg:text-5xl text-center mt-10 font-bold text-black mb-10">
          Programa de entrenamiento
        </h1>
        <div className="flex flex-row justify-center gap-5 flex-wrap items-center">
          <ProgramsCard
            description={
              "La Kata es la repetición continua de movimientos de Karate-Do con el objetivo de perfeccionar su correcto aprendizaje y utilización."
            }
            title={"KATA"}
            order={1}
            image={
              "https://cdn.midjourney.com/63bc6b3e-3270-4c67-9e2b-4044694d705d/0_1_384_N.webp"
            }
          />

          <ProgramsCard
            description={
              "El Kumite es la práctica en un combate controlado para mejorar timing, distancia, precisión y adaptación. Varía según habilidad e intensidad deseada."
            }
            title={"KUMITE"}
            order={2}
            image={
              "https://cdn.midjourney.com/b4454a58-a296-4b6f-b1db-2d96596beb73/0_1_384_N.webp"
            }
          />

          <ProgramsCard
            description={
              "El Kobudo es el entrenamiento para aprender el manejo y la utilización de armas tradicionales de Okinawa de manera eficiente y efectiva."
            }
            title={"KOBUDO"}
            order={3}
            image={
              "https://cdn.midjourney.com/deb0f62a-ea8e-4cb4-b585-8a96d3792be6/0_0_384_N.webp"
            }
          />

          <ProgramsCard
            description={
              "El programa Antibullying prepara a nuestros alumnos a saber reaccionar física y mentalmente ante situaciones de Bullying."
            }
            title={"ANTI-BULLYING"}
            order={4}
            image={
              "https://cdn.midjourney.com/d1dcd966-95fe-482a-9617-f8a504401773/0_2.png"
            }
          />
        </div>
      </div>
    </>
  );
}

export default Programs;
