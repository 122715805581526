import React from "react";
import Hero from "../components/Hero";
import QuienesSomos from "../components/QuienesSomos";
import Programs from "../components/Programs";
import Contact from "../components/Contact";
import ChatBot from "../components/ChatBot";

function Home() {
  return (
    <>
      <div className="font-poppins overflow-hidden">
        <Hero />
        <QuienesSomos />
        <Programs />
        <Contact />
      </div>
    </>
  );
}

export default Home;
