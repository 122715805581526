import React from "react";
import Map from "./Map";
import{HiOutlineMailOpen} from "react-icons/hi";
import {BsWhatsapp} from "react-icons/bs";

function Contact() {
  return (
    <div className="w-full flex flex-col lg:flex-row justify-center items-center text-center gap-10 lg:gap-28 py-24 bg-black text-white">
      <div>
        <h1 className="text-5xl font-bold">Contacto</h1>
        <h3 className="text-3xl font-bold text-RojoKumo my-4 leading-7">Kumo<br/> Karate Do<br/>& Kobudo Dojo</h3>
        <p>
        Agua #325, <br />Jardines del
          Pedregal <br /> C.P. 01900, Álvaro Obregón.
        </p>
        <p className="flex flex-row justify-center items-center"><HiOutlineMailOpen size={20} className="mr-4"/>kumokarate@mail.com</p>
        <p className="flex flex-row justify-center items-center"><BsWhatsapp size={20} className="mr-4 cursor-pointer" onClick={() => window.open("http://wa.me/525569661949", "_blank")}/>55 6966 1949</p>
      </div>
      <Map />
    </div>
  );
}

export default Contact;
