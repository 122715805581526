import * as React from "react";
const Logo = (props) => (
  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Capa_1"
      x={0}
      y={0}
      style={{
        enableBackground: "new 0 0 355 354",
      }}
      viewBox="0 0 355 354"
      {...props}
    >
      <style>{`.st0{fill:${props.fill}`}</style>
      <path
        d="M348.38 307.75c-.29.98-.56 1.9-.83 2.81.3-.92.57-1.86.83-2.81zM303.82 155.84c2.17.14 4.37.13 6.54-.04 4.04-.32 6.68-1.13 8.31-2.88 2.4-2.57 2.28-6.37 2.22-7.89-.18-5.1.81-27.42 2.8-72.05.35-7.81.57-14.31-3.31-17.75-3.13-2.77-8.86-2.66-18.94-1.17-.96.14-1.91.37-2.87.49-3.82.47-5.34 2.56-5.21 6.38.24 6.72.19 13.46.23 20.19.11 21.35-1.8 42.7-.41 64.06.53 7.92 2.68 10.13 10.64 10.66zM311.53 38.23c14.21-.59 14.33-.85 13.8-14.97-.11-3.05-1.22-4.77-3.67-6.34-6.35-4.07-13.13-7.16-20.4-9.18-8.05-2.24-8.05-2.22-8.01 6.28.01 2.4 0 4.8 0 7.21.52 17.74.52 17.74 18.28 17zM212.3 94.7h-13.76c-.32 1.54-.88 4.24-1.59 7.58-5.97 28.18-8.39 57.09-16.94 84.72-7.27 23.49-1.41 29.46 25.38 30.19 4.19.11 7.28-.35 8.96-2.11 2.26-2.37 1.35-6.11 1.17-6.99-1.52-7.32-2.51-50.94-3.22-113.39z"
        className="st0"
      />
      <path
        d="M344.15 55.21c-4.85 2.97-5.11 9.08-5.13 9.73 5.02 43.72 6.75 76.1 5.32 85.74-.31 2.08-1.03 6.92-4.63 11.35-3.48 4.29-7.97 6.1-9.74 6.8-6.19 2.45-12.65 3.33-19.21 3.34-4.03.01-6.01 1.01-6.44 5.46-.67 6.91-2.3 13.72-3.36 20.6-3.45 22.28-12.45 31.22-35.14 34.22-6.71.89-13.52.96-20.27 1.52-1.37.11-3.11.44-2.95 2.35.12 1.46 1.6 1.59 2.79 1.73 1.2.14 2.42.19 3.63.27 32.69 1.95 65.42 1.86 98.16 2 1.42.01 2.47-.16 3.28-.47V61.33c0-1.19-.11-2.36-.18-3.53-.43-1.32-.84-2.63-1.43-4.04-1.18.07-2.94.38-4.7 1.45z"
        className="st0"
      />
      <path
        d="M245.32 215.63c10.2-.76 17.8-1.89 20.39-2.93 1.03-.42 1.94-.97 1.94-.97 1.67-1.03 3-2.26 3.94-3.8.93-1.51 1.23-2.89 1.36-3.56.68-3.41 2.42-15.52 4.48-32.01.42-1.92.66-4.59-.57-7.11-1-2.04-2.44-2.93-3.51-3.95-3.19-3.06-3.58-7.81-3.54-13.04.1-15.86-.46-31.73-.31-47.6.01-.97.21-3.54-1.4-4.98-1.56-1.4-3.99-1.04-4.85-.92-4.33.57-12.14.56-24.3-.07-1.69.35-4.3 1.19-5.8 3.41-1.54 2.29-1.19 4.98-1.05 5.88.9 5.74 4.95 48.99 10.52 109.43.91.75 1.8 1.49 2.7 2.22zM46.81 283.62c3.13-1.97 8.23-3.91 7.05-7.99-.9-3.1-6.02-1.23-9.24-1.54-3.12-.3-6.3.02-9.45-.09-10.91-.4-21.7-1.13-29.65-11.42v30.08c0 4.01.43 7.93 1.23 11.71.43 1.17.8 2.35 1.33 3.52 11.06-11.67 25.97-16.22 38.73-24.27zM283.38 298.12c-.6-6.71-3.82-11.19-9.8-13.95-8.45-3.91-17.54-5.33-26.63-6.69-3.38-.51-4.8.88-4.79 4.39.07 22.1.06 44.2-.1 66.3 0 .53.07.9.13 1.3h45.23c-.01-.35.02-.67-.05-1.1-2.68-16.64-2.49-33.51-3.99-50.25zM78.85 157.78c4.81-4.5 7.28-8.22 7.28-8.22 7.25-10.9 7.75-16.43 12.75-23.58 1.92-2.75 8.24-11.78 19.14-15.21 11.45-3.6 22.84.6 25.91 1.86.24.1.41.17.59.25 15.82 6.73 18.77 16.12 23.2 15.01 3.77-.94 2.24-7.85 6.54-24.63.32-1.24 1.08-4.12-.42-6.03-1.06-1.35-3.1-1.87-6.02-1.85-11.87.06-23.75.02-35.62.02v-.58c-11.64 0-23.27-.02-34.91.02-2.14.01-4.38-.41-6.34.94-6.95 4.78-14.28 9.12-20.79 14.43-15.82 12.91-18.26 30.48-7.18 47.57 2.15 3.32 5.21 4.95 8.24 4.85.79-.03 2.76-.3 7.63-4.85z"
        className="st0"
      />
      <path
        d="M350.47 260.06c-1.39-1.46-2.78-2.57-3.84-3.28-12.76 0-80.43-.02-93.19-.02h-10.86c2.2 3.56 4.89 2.86 7.13 3.18 13.42 1.93 26.92 3.45 39.34 9.51 10.66 5.2 17.55 12.87 19.48 25.05 2.63 16.67 4.22 33.32 2.76 51.78.99-.22 2.21-.55 3.53-.93 15.39-6.21 27.44-18.95 32.72-34.78.27-.91.55-1.83.83-2.81 1.33-4.81 2.1-9.86 2.1-15.09v-32.61zM55.88 244.01c.13-2.4.02-4.8.02-7.21h-.03c0-4.08.01-8.17-.01-12.25-.01-1.69.09-3.83-1.77-4.38-7.48-2.23-14.76-1.21-21.94 1.68-5.61 2.26-5.26 6.98-5.13 11.51.17 5.51.73 11.02 1.15 16.52.11 1.41.12 2.79 1.33 3.94 2.97 2.82 16.74 3.9 21.88 1.88 5.95-2.34 4.27-7.35 4.5-11.69zM137.26 259.24c-3.1 9.21-10.76 12.18-19.53 13.84-8.94 1.69-17.9.46-26.84.84-3.24.14-7.27-.58-8.85 3.33-5.28 13.07-16.57 19.57-28.16 25.8-6.59 3.54-13.62 6.29-19.63 10.84-8.29 6.28-8.92 11.53-2.25 19.28 1.56 1.82 3.34 3.5 5.22 5 8.08 6.47 15.26 5.56 20.8-2.97 5.9-9.07 11.29-18.46 17.1-27.59 6.25-9.83 15.73-13.79 27.12-13.91 14.28-.15 28.56-.38 42.85-.3 3.69.02 5.92-.71 6.45-4.69 1.29-9.58 7.66-15.15 15.86-19.15 10.58-5.16 21.96-7.27 33.55-8.66 3.88-.46 8.02-.07 11.3-3.99h-71.2c-1.75 0-3.06.15-3.79 2.33zM77.06 54.2c-.09-1.2-.16-2.39-.18-2.79 0-1.52-.05-2.24.01-2.95.75-9.62-1.13-12.85-7.68-13.15-9.24-.42-11.24 1.66-11.29 11.95-.01 2.63.25 5.27.49 7.89.69 7.56 4.29 10.46 12.34 9.72 6.96-.65 6.68-5.67 6.31-10.67zM102.38 50.13h-.03v5.73c-.01 8.06 1.79 9.73 9.85 9.13 10.03-.75 14.46-12.14 8.76-22.57-2.33-4.26-10.54-8.01-14.79-6.36-5.05 1.96-3.8 6.53-3.79 10.49v3.58z"
        className="st0"
      />
      <path
        d="M147.16 320.79c.48-4.85.11-6.76-5.51-6.61-12.98.35-26.02 1.84-38.95-1.08-4.32-.97-6.56 1.7-8.08 5.46-4.3 10.68-8.27 21.55-16.25 30.91h66.88c-.99-9.92.98-19.31 1.91-28.68zM241.77 11.39c7.73 4.43 12.92 10.31 16.3 15.06a90.898 90.898 0 0 1 2.43 27.94 91.153 91.153 0 0 1-5.31 25.07c3.1.02 6.19.05 9.29.09 3.18.05 4.81-.53 4.8-4.26-.01-4.27-.43-58.62.53-65.64.09-.62.44-2.77-.79-4.14-.48-.53-1.09-.82-1.67-.99h-47.19c1.76 1.08 10.85.7 21.61 6.87zM44.58 202.67c3.99-.57 7.22-1.44 9.36-2.1 2.78-6.61 5.36-15.92 1.38-23.72-3.49-6.85-10.05-6.41-15.16-13.97-5.8-8.59-8.21-18.93-7.95-30.35.4-17.92 12.29-29.05 26.03-38.5 4.74-3.26 10.05-5.75 14.63-11.49-8.13-.36-15.07-.8-21.86-2.5-9.03-2.26-15.27-6.93-16.14-16.9-.52-5.97-1.82-11.89-2.09-17.86-.62-13.47 4.27-19.91 17.46-23.33 3.96-1.02 8.12-1.25 12.18-1.92 3.57-.59 7.2-1.03 10.67-2 1.65-.46 2.66-1.19 3.25-2.06.6-.89.87-1.89.26-8.23-.15-1.58-.27-2.56-.4-3.22H62.33C31.01 4.52 5.52 30 5.52 61.33v153.21c9.01-7.71 16.34-10.45 29.62-11.23 3.48-.2 5.33-.05 9.44-.64zM102.02 7.05c-1.29 4.56.84 7.6.84 7.6 1.29 1.91 3.47 3.26 6.32 3.63 1.92.25 3.87.24 5.8.38 5.67.4 16.28 2.07 21.04 7.01 8.03 8.33-.57 23.25 6.84 28.44 2.7 1.89 6.2 1.56 8.49 1.1-1.87-9.59-1.49-17.38-.87-22.45.89-7.26 1.56-11.87 5.15-15.88 3.22-3.59 8.19-5.62 15.74-7.04 6.09-1.15 12.53-.45 18.36-4.41-3.82-.33-7.45-.62-10.96-.9h-73.62c-1.93.74-2.87 1.6-3.13 2.52zM217.22 346.95c-.39-4.25-1.23-31.24-2.36-69.07-.73-.15-1.86-.35-3.24-.52-2.85-.34-16.83.74-29.94 8.05-3.29 1.84-5.98 4.2-6.39 8.18-1.84 17.9-4.97 35.68-4.58 53.79.02.84.11 1.5.23 2.09h46.11c.28-1.04.21-2.09.17-2.52z"
        className="st0"
      />
      <path
        d="M236.35 59.75c.1-9.45-.78-15.01-1.46-22.31-.94-10.08-6.46-14.95-14.82-13.37-1.87.35-3.59.26-3.29 3.38 1.32 13.9.47 27.86.4 41.79-.04 8.21 1.02 9.72 9.44 9.94 3.4.09 6.8.14 10.2.17-.8-3.43-.58-9.64-.47-19.6zM183.12 79.02c1.21 0 4.51.03 6.59-2.16 1.29-1.35 1.92-3.45 1.79-6.57-.52-12.48-.01-25.01.03-37.51.03-9.24-2.73-10.84-11.52-6.88-2.91 1.31-4.64 3.2-4.83 6.51-.27 4.56-1.38 9.09-1.18 13.65.37 8.49-2.53 17.76-3.84 20.71-6.46 14.48-36.17 14.64-41.96 14.59 28.11-1.35 48.44-2.34 54.92-2.34zM82.67 236.92c0 4.81-.01 9.61 0 14.42.01 2.41.72 4.09 3.6 4.38 7.01.69 13.98 1.52 20.99.04 2.74-.58 4.83-1.52 5.82-4.58 6.64-20.62-.79-31.19-22.25-31.97-5.9-.21-9.34.67-8.13 7.64.57 3.27.1 6.71.1 10.08-.05-.01-.09-.01-.13-.01zM182.98 232.06c-15.22-2.68-26.03-12.72-27.96-25.58-.63-4.17-.31-5.61 3.35-24.02 5.1-25.66 5.38-31.07 4.75-33.8-.07-.29-.16-.57-.28-.96-3.03-9.54-12.53-19.02-23.8-19.82-11.12-.79-19.26 7.22-23.58 11.47-6.05 5.96-9.77 13.34-11.47 16.57-4.56 8.63-5.02 13.23-10.57 17.58-4.74 3.72-7.21 2.42-9.72 6.25-2.77 4.23-2.27 9.78-1.54 16.08.54 4.7 2.15 6.5 6.38 6.72 6.29.32 12.58.97 18.87.82 11.03-.26 22.58 7.23 28.01 15.35 5.84 8.73 1.74 13.92 7.45 18.79 2.82 2.41 5.48 2.56 28.17 2.28 16.84-.2 19.55-.41 22.22-.5 7.3-.25 14.59-.79 21.81-2.21-4.26-2.67-8.8-2.81-13.31-3.19-6.27-.5-12.61-.74-18.78-1.83z"
        className="st0"
      />
    </svg>
  )
);
export default Logo;
