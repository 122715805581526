import React from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

function QuienesSomos() {
  return (
    <>
      <BrowserView
        className="w-full h-screen bg-black bg-no-repeat bg-cover bg-left-top"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dt9ivv2ug/image/upload/v1692227267/QuienesSomosHorizontal.jpg')",
        }}
        alt="Karate Niños en Clase"
      >
        <div className="flex flex-row text-white">
          <div className="w-[50%] h-screen"></div>
          <div className="w-[50%] h-screen flex flex-col justify-center items-center">
            <h1 className="text-5xl font-bold text-black">Quienes Somos</h1>
            <p className="text-black w-96 mt-10 text-center">
              Somos una academia de Karate Do Shito Ryu ubicada en el sur de la
              CDMX, afiliados a la Federación Mexicana de Karate (FEMEKA).
              Nuestro objetivo principal es trabajar en el bienestar del alumno
              a través de la práctica del Karate Do, mediante valores como el
              respeto, la perseverancia, la superación personal y el logro de
              objetivos a través del esfuerzo continuo.
            </p>
          </div>
        </div>
      </BrowserView>

      <MobileView
        className="w-full h-screen bg-black bg-no-repeat bg-cover bg-bottom"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dt9ivv2ug/image/upload/v1692230774/QuienesSomosVertical.jpg')",
        }}
        alt="Karate Niños en Clase"
      >
        <div className="flex flex-col text-white">
          <div className="w-full h-[50%] flex flex-col justify-center items-center">
            <h1 className="text-4xl font-bold text-black mt-10 md:mt-20 p-4 text-center">
              Quienes Somos
            </h1>
            <p className="text-black w-full text-center px-10 md:px-16">
              Somos una academia de Karate Do Shito Ryu ubicada en el sur de la
              CDMX, afiliados a la Federación Mexicana de Karate (FEMEKA).
              Nuestro objetivo principal es trabajar en el bienestar del alumno
              a través de la práctica del Karate Do, mediante valores como el
              respeto, la perseverancia, la superación personal y el logro de
              objetivos a través del esfuerzo continuo.
            </p>
          </div>
        </div>
      </MobileView>
    </>
  );
}

export default QuienesSomos;
